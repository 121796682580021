/* base css */
.parent { 
  
  background-color: rgb(75, 80, 83);
  padding: 1rem 
}
.child {
  text-align: center;
  white-space: nowrap;
  padding: 1rem;
    margin: auto;
  width: 100%;
  /*border: 3px solid green;*/
  padding: 10px
}


.parent_bin_menu {
  text-align: center;
  white-space: nowrap;
  padding: 1rem 
}
.grid-parent_bin_menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 
}
.grid-parent {
  
  display: grid;
  grid-template-columns: calc(100%) }
.parent_boxes_internal {
  background: #bbbaba;
  padding: 1rem 
}
.grid-parent_boxes_internal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color: rgb(164,172,176);
}
.parent_boxes {
  background: #808080;
  padding: 1rem 
}
.grid-parent_boxes_top {
  display: grid;
  grid-template-columns: 7% 15% auto 15% 7% 
}

.grid-parent_boxes_bottom {
    display: grid;
    grid-template-columns: 7% auto 7% 
  }

/*input[type="checkbox"] {
  width: 40px;
  height: 70px;
  background: red;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  position: relative;
  margin-left: 5%;
}
.checkbox-container {
  position: absolute;
  display: inline-block;
  margin: 20px;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
input[type="checkbox"]:checked {
  background: blue;
  */
  body {
      background-color: rgb(75, 80, 83);
      color: rgb(235,235,235);
      
 }
  *, *:before, *:after {
      box-sizing: border-box;
 }
  *, *:before, *:after {
      box-sizing: border-box;
 }
  html {
      font-size: 16px;
 }
  .plane {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
      margin-left: 15vw ;
      max-width: 150px;
      max-height: 300px;
      
      

      
 }
  .cockpit {
      height: 100px;
     /*60*/
      position: relative;
      overflow: hidden;
      text-align: center;
      border-bottom: 0px solid #d8d8d8;
      

 }
  .cockpit:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 200px;
     /*120*/
      width: 100%;
      border-radius: 100%;
      border: 2px solid rgb(0, 0, 0);
      background-color: rgb(130, 134, 137);
 }
  .cockpit h1 {
      width: 100%;
      margin: 30px auto 17px auto;
      background-color: rgb(130, 134, 137);
 }
  .cockpit_inv {
      height: 60px;
      position: relative;
      overflow: hidden;
      text-align: center;
      border-bottom: 0px solid #d8d8d8;
      transform: rotate(180deg);
      
      
 }
  .cockpit_inv:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 120px;
      width: 100%;
      border-radius: 25%;
      border: 2px solid rgb(0, 0, 0);
      transform: rotate(180deg);
      background-color: rgb(130, 134, 137);
      
 }
  .cockpit_inv h1 {
      width: 60%;
      margin: 50px auto 17px auto;
      transform: rotate(180deg);
 }
  .exit {
      position: relative;
      height: 50px;
 }
  .exit:before, .exit:after {
      content: "EXIT";
      font-size: 14px;
      line-height: 18px;
      padding: 0px 2px;
      font-family: "Arial Narrow", Arial, sans-serif;
      display: block;
      position: absolute;
      background: #008000;
      color: white;
      top: 50%;
      transform: translate(0, -50%);
 }
  .exit:before {
      left: 0;
 }
  .exit:after {
      right: 0;
 }
  .fuselage {
      border-right: 2px solid rgb(0, 0, 0);
      border-left: 2px solid rgb(0, 0, 0);
      min-height: 500px;
      min-width: 50px;
      background-color: rgb(130, 134, 137);
      
      
     
      
      
 }
  ol {
      list-style: none;
      padding: 0;
      margin: 0;
      
 }
  .seats {
    
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin-left: 5%;
 }
  .seat {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    
      margin-top: 15%;
      display: flex;
      flex: 0 0 45%;
      padding: 5px;
      position: relative;
 }
  .seat:nth-child(1) {
      margin-right: 5%;
   
 }
  .seat input[type=checkbox] {
      position: absolute;
      opacity: 0;
 }
 /*.seat input[type=checkbox]:checked + label {
      background: #bada55;
      -webkit-animation-name: rubberBand;
      animation-name: rubberBand;
      animation-duration: 300ms;
      animation-fill-mode: both;
 }
  .seat input[type=checkbox]:disabled + label {
      background: #dddddd;
      text-indent: -9999px;
      overflow: hidden;
 }
  .seat input[type=checkbox]:disabled + label:after {
      content: "X";
      text-indent: 0;
      position: absolute;
      top: 4px;
      left: 50%;
      transform: translate(-50%, 0%);
 }
  .seat input[type=checkbox]:disabled + label:hover {
      box-shadow: none;
      cursor: not-allowed;
 }
  */
  label {
      display: inline-block;
      width: 90%;
      height: 125%;
      text-align: center;
      border: 0px solid #ddd;
      line-height: 25px;
      cursor: pointer;
      background: #ff0000;
      color: #fff;
      border: 1px solid rgb(0, 0, 0);
      font-size: 12px;
 }

 label_first_aid {
  display: inline-block;
  width: 90%;
  height: 125%;
  text-align: center;
  border: 0px solid #ddd;
  line-height: 25px;
  cursor: pointer;
  background: rgb(170,170,170);
  color:white;
  border: 1px solid rgb(0, 0, 0);
  font-size: 24px;
  font-weight: bold;
}

  .seat input[type=checkbox]:checked + label {
      /*background: #bada55;*/
      -webkit-animation-name: rubberBand;
      animation-name: rubberBand;
      animation-duration: 0ms;
      animation-fill-mode: both;
 }
 /*.seat label {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5rem;
      padding: 4px 0;
      background: # ;
      border-radius: 5px;
      animation-duration: 300ms;
      animation-fill-mode: both;
      min-height: 300%;
 }
  .seat label:before {
      content: "";
      position: absolute;
      width: 75%;
      height: 75%;
      top: 1px;
      left: 50%;
      transform: translate(-50%, 0%);
      background: rgba(255, 255, 255, 0.4);
      border-radius: 3px;
 }
  */
  @-webkit-keyframes rubberBand {
      0% {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
     }
      30% {
          -webkit-transform: scale3d(1.25, 0.75, 1);
          transform: scale3d(1.25, 0.75, 1);
     }
      40% {
          -webkit-transform: scale3d(0.75, 1.25, 1);
          transform: scale3d(0.75, 1.25, 1);
     }
      50% {
          -webkit-transform: scale3d(1.15, 0.85, 1);
          transform: scale3d(1.15, 0.85, 1);
     }
      65% {
          -webkit-transform: scale3d(0.95, 1.05, 1);
          transform: scale3d(0.95, 1.05, 1);
     }
      75% {
          -webkit-transform: scale3d(1.05, 0.95, 1);
          transform: scale3d(1.05, 0.95, 1);
     }
      100% {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
     }
 }
  @keyframes rubberBand {
      0% {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
     }
      30% {
          -webkit-transform: scale3d(1.25, 0.75, 1);
          transform: scale3d(1.25, 0.75, 1);
     }
      40% {
          -webkit-transform: scale3d(0.75, 1.25, 1);
          transform: scale3d(0.75, 1.25, 1);
     }
      50% {
          -webkit-transform: scale3d(1.15, 0.85, 1);
          transform: scale3d(1.15, 0.85, 1);
     }
      65% {
          -webkit-transform: scale3d(0.95, 1.05, 1);
          transform: scale3d(0.95, 1.05, 1);
     }
      75% {
          -webkit-transform: scale3d(1.05, 0.95, 1);
          transform: scale3d(1.05, 0.95, 1);
     }
      100% {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
     }
 }
  .rubberBand {
      -webkit-animation-name: rubberBand;
      animation-name: rubberBand;
 }
 /* Caixa que contém os botões centrais*/
  .f1 {
      display:inline-block;
      position: relative;
      height: 100%;
      width: 70%;
      min-width: 40rem;
      margin: auto;
      padding: 10px;
      background-color: rgb(164,172,176);
 }
  .button_boxes_dark{
      background-color: rgb(116,118,120);
      box-shadow: none;
      padding: 0;
      border: 3px solid rgb(0, 0, 0);
      height: 4rem;
      width: 4rem;
      font-weight: bold;
      margin: auto;
 }
  .button_boxes_light{
      background-color: rgb(194,218,218);
      box-shadow: none;
      padding: 0;
      border: 3px solid rgb(0, 0, 0);
      height: 4rem;
      width: 4rem;
      font-weight: bold;
      margin: auto;
 }
  .twenty {
      height: 20%;
      width: 100%;
      margin: 5px auto;
 }
  .thirty {
      height: 30%;
      width: 100%;
      margin: 5px auto;
 }
  .forty {
      height: 40%;
      width: 100%;
      margin: 5px auto;
 }
  .fifty {
      height: 50%;
      width: 100%;
      margin: 5px auto;
 }
  .sixty {
      height: 600%;
      width: 100%;
      margin: 5px auto;
 }
  .boxes_lock_unlock_all_container {
      background-color: rgb(130, 134, 137);
      box-shadow: none;
      padding: 0;
      border: none;
      width: 15%;
      aspect-ratio: 1/1;
      font-weight: bold;
      font-size: 1em;
      margin: auto;
      color: black;
      text-align: center;
 }
  .boxes_lock_unlock_all_bottom{
    
      background-color: rgb(194,218,218);
      box-shadow: none;
      padding: 0;
      border: 3px solid rgb(0, 0, 0);
      height: 50%;
      width: 50%;
      font-weight: bold;
      margin: 25%;
      margin-bottom: 15%;
      font-size: 0.75em;
 }
  .boxes_smoke_alert_container{
      background-color: rgb(130, 134, 137);
      box-shadow: none;
      padding: 0;
      border: none;
      height: 35%;
      width: 15%;
      font-weight: bold;
      font-size: 0.75em;
 }
  .boxes_smoke_alert_red_square{
      background-color: red;
      box-shadow: none;
      padding: 0;
      border: 2px solid black;
      width: 10%;
      aspect-ratio: 1/1;
      font-weight: bold;
      margin-left: 45%;
      margin-top: 10%;
      margin-bottom: 5%;
 }
  .boxes_smoke_alert_smoke_detected{
      background-color: red;
      box-shadow: none;
      padding: 0;
      border: none;
      width: 80%;
      aspect-ratio: 3/1;
      font-weight: bold;
      font-size: 0.75em;
      margin-left: 10%;
      margin-top: 0%;
 }
  .boxes_smoke_alert_smoke_reset{
      background-color: rgb(194,218,218);
      box-shadow: none;
      padding: 0;
      border: 3px solid rgb(0, 0, 0);
      width: 50%;
      aspect-ratio: 1/1;
      font-weight: bold;
      margin: 25%;
      margin-top: 15%;
      font-size: 0.75em;
 }

/* inicio popup */


  .pen-title {
    position: relative;
    left: 0;
    right: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    color: white;
    font-size: 24px;
  }
  .pen-title .miniature {
    width: 100px;
    height: 130px;
    padding-top: 5px;
    background: transparent;
    margin: auto;
    text-align: center;
  }
  .pen-title .miniature .mini-menu {
    width: 66%;
    margin: auto;
    height: 5px;
    background: #fff;
    margin-top: 5px;
  }
  .pen-title .miniature .mini-menu:nth-of-type(4) {
    margin-bottom: 10px;
  }
  .pen-title .miniature .miniblock {
    width: 30px;
    height: 30px;
    background: tomato;
    display: inline-block;
  }
  .pen-title p {
    font-size: 12px;
  }
  .clickhere {
    float: left;
    color: white;
    font-size: 12px;
  }
  /*.menubtn {
    cursor: pointer;
    width: 40px;
    height: 40px;
    left: 0;
    top: 10px;
    position: relative;
  }*/
  .menubtn span {
    display: block;
    width: 30px;
    height: 3px;
    position: relative;
    background: #fff;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .menubtn span::before {
    content: '';
    width: 30px;
    height: 3px;
    position: relative;
    top: 8px;
    background: #fff;
  }
  .menubtn span::after {
    content: '';
    width: 30px;
    height: 3px;
    position: absolute;
    top: 16px;
    background: #fff;
  }
  .menubtn.opened span {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
  }
  .menubtn.opened span::before {
    opacity: 0;
  }
  .menubtn.opened span::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 70px;
  }
  .navmenu {
    width: 50vw;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 100px;
    margin-left: 100px;
    background: #fff;
    text-align: center;
    position: absolute;
    left: 10px;
    top: 25px;
    /*box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);*/
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .navmenu.opened { 
    visibility: visible;
    opacity: 1;
  }
  /*.navmenu::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 7px;
    width: 15px;
    height: 15px;
    background: #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }*/
  .navmenu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .navmenu ul.text-list {
    text-align: left;
    width: 90%;
    margin: auto;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navmenu ul.text-list li a {
    text-decoration: none;
    padding-left: 5px;
    padding-right: 5px;
    color: #343434;
    font-weight: 600;
    display: block;

  }
  .navmenu ul.text-list li a:hover {
    color: tomato;
  }
  .navmenu ul.thumbs-list {
    margin: 10px auto;
    padding: 0;
    width: 90%;
  }
  .navmenu ul.thumbs-list::after {
    display: table;
    clear: both;
    content: '';
  }
  .navmenu ul.thumbs-list li {
    display: block;
    float: left;
    height: 50px;
    width: 50px;
    background: steelBlue;
    margin: 5px;
  }
  .navmenu ul.thumbs-list li a {
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
  }
  .navmenu ul.thumbs-list li a i {
    line-height: 98px;
    font-size: 40px;
  }
  /*
  .navmenu ul.thumbs-list li:nth-of-type(2) {
    background: steelBlue
  }*/
  .navmenu ul.thumbs-list li:hover {
    opacity: .8;
  }
  
  /* fim popup */

   /* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
} 